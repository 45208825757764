import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Logo from '../../images/logo.svg';
import ScrollEffect from '../animations/components/scroll-effect';
import StyledLink from '../primitives/styled-link';
import RowSet from '../primitives/grid/row-set';
import { Drawer, DrawerContent } from '../primitives/drawer';
import Container from '../primitives/grid/container';
import VisuallyHidden from '../primitives/visually-hidden';
import Row from '../primitives/grid/row';
import Col from '../primitives/grid/col';

const MobileHeader = () => {
    const {
        wp: {
            acfOptionsOptions: {
                siteOptions: {
                    address,
                    email,
                    linkedin,
                    phoneNumber,
                    twitter,
                },
            },
        },
        wpMenu: {
            menuItems: {
                nodes: menuItems,
            },
        },
    } = useStaticQuery(graphql`
        {
            wp {
                acfOptionsOptions {
                    siteOptions {
                        address
                        email
                        linkedin
                        phoneNumber
                        twitter
                    }
                }
            }
            wpMenu(name: { eq: "Primary Menu" }) {
                menuItems {
                    nodes {
                        label
                        url
                    }
                }
            }
        }
    `);

    const drawerRef = React.useRef<HTMLDivElement>(null);

    const [open, setOpen] = React.useState(false);

    return (
        <div
            css={{
                width: '100%',
                height: 'auto',
                position: 'sticky',
                top: '0',
                zIndex: '20',
                alignItems: 'flex-start',
                flexDirection: 'column',
                backgroundColor: 'var(--color-shades-white)',
                display: 'none',
                '@media (max-width: 767px)': {
                    display: 'flex',
                },
                pointerEvents: 'auto',
            }}
        >
            <div ref={drawerRef} />
            <Container css={{
                zIndex: '2000',
            }}
            >
                <div
                    css={{
                        width: '100%',
                        display: 'flex',
                        padding: '20px 0px',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        css={{
                            width: '100%',
                            height: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <ScrollEffect runOnce={false} inViewProperties={{ x: [-10, 0], opacity: [0, 1] }}>
                            <Link to="/" onClick={() => {
                                setOpen(false)
                            }}>
                                <Logo css={{
                                    width: '136px',
                                    height: '25px',
                                    objectFit: 'cover',
                                }}
                                />
                            </Link>
                        </ScrollEffect>
                        <button
                            type="button"
                            css={{
                                width: 'auto',
                                height: 'auto',
                                display: 'flex',
                                padding: '20px 0',
                                flexDirection: 'column',
                            }}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            <VisuallyHidden>
                                Toggle Menu
                            </VisuallyHidden>
                            <div
                                css={{
                                    width: '40px',
                                    height: '1px',
                                    marginBottom: '12px',
                                    backgroundColor: 'var(--color-primary-charcoal)',
                                    transformOrigin: '45% center',
                                    transition: '0.3s',
                                }}
                                style={{
                                    transform: open ? 'rotate(45deg) scale(0.8) translateX(10px)' : '',
                                }}
                            />
                            <div
                                css={{
                                    width: '40px',
                                    height: '1px',
                                    backgroundColor: 'var(--color-primary-charcoal)',
                                    transformOrigin: '45% center',
                                    transition: '0.3s',
                                }}
                                style={{
                                    transform: open ? 'rotate(-45deg) scale(0.8) translateX(10px)' : '',
                                }}
                            />
                        </button>
                    </div>
                </div>

            </Container>
            {drawerRef?.current && (
                <Drawer open={open}>
                    <DrawerContent animation="fade" placement="top" size="full" container={drawerRef?.current || undefined}>
                        <Container css={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div
                                css={{
                                    flexGrow: '1',
                                    marginTop: '94px',
                                    width: '100%',
                                    height: 'auto',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    '@media (max-width: 767px)': {
                                        marginRight: '0px',
                                    },
                                }}
                            >
                                <RowSet
                                    breakpoints={{ dt: { between: 28 } }}
                                    css={{
                                        padding: '24px 0px',
                                        borderTop: '1px solid #3b7a57',
                                        borderBottom: '1px solid #3b7a57',
                                        margin: 'unset',
                                    }}
                                >
                                    {menuItems && menuItems.map(({ label, url }: any, idx) => (
                                        <ScrollEffect
                                            runOnce={false}
                                            inViewProperties={{ opacity: [0, 1], y: [10, 0] }}
                                            inViewDelay={0.05 * idx}
                                        >
                                            <StyledLink
                                                to={url}
                                                onClick={() => {
                                                    setOpen(false)
                                                }}
                                                css={{
                                                    fontSize: '16px',
                                                    lineHeight: '22px',
                                                }}
                                            >
                                                {label}
                                            </StyledLink>
                                        </ScrollEffect>

                                    ))}
                                </RowSet>
                            </div>
                            <Row
                                css={{
                                    borderTop: '1px solid #304d3a',
                                    padding: '24px 0',
                                }}
                            >
                                <Col
                                    breakpoints={{ mb: { span: 12 } }}
                                    css={{
                                        fontWeight: '300',
                                        fontSize: '14px',
                                        fontFamily: 'Approach',
                                        lineHeight: '20px',
                                        marginBottom: '24px',
                                    }}
                                >
                                    <a href={`tel:${phoneNumber as string}`}>{phoneNumber}</a>
                                    <a href={`mailto:${email as string}`}>{email}</a>
                                </Col>
                                <Col
                                    breakpoints={{ mb: { span: 6 } }}
                                    css={{
                                        fontWeight: '300',
                                        fontSize: '14px',
                                        fontFamily: 'Approach',
                                        lineHeight: '20px',
                                        gridRow: '2',
                                    }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: address }} />

                                </Col>
                                <Col
                                    breakpoints={{ mb: { span: 6 } }}
                                    css={{
                                        fontWeight: '300',
                                        fontSize: '14px',
                                        fontFamily: 'Approach',
                                        lineHeight: '20px',
                                        gridRow: '2',
                                    }}
                                >
                                    {linkedin && <a href={linkedin}>linkedin</a>}
                                    {twitter && <a href={twitter}>Twitter</a>}
                                </Col>
                            </Row>
                        </Container>
                    </DrawerContent>
                </Drawer>
            )}
        </div>
    );
};

export default MobileHeader;
